import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 考勤组 - 列表查询
 * @param params
 */
export function getAttendanceGroupList(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/attendanceGroup/list',
    method: 'get',
    params,
  });
}

/**
 * 考勤组 - 列表查询分页
 * @param params
 */
export function getAttendanceGroupPage(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/attendanceGroup/page',
    method: 'get',
    params,
  });
}

/**
 * 考勤组 - 创建
 * @param params
 */
export function createAttendanceGroup(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/attendanceGroup/create',
    method: 'post',
    data: params,
  });
}

/**
 * 考勤组 - 修改
 * @param params
 */
export function updateAttendanceGroup(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/attendanceGroup/update',
    method: 'post',
    data: params,
  });
}

/**
 * 考勤组 - 删除
 * @param params
 */
export function deleteAttendanceGroup(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/attendanceGroup/delete',
    method: 'delete',
    params,
  });
}

/**
 * 校验员工是否已添加考勤组
 * @param params
 */
export function employeeCheck(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/attendanceGroup/check',
    method: 'post',
    data: params,
  });
}
