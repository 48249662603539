var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "attendance-group-dialog" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "考勤名称", prop: "attendanceGroupName" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.dataset.isDefault,
                      maxlength: "10",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.ruleForm.attendanceGroupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "attendanceGroupName", $$v)
                      },
                      expression: "ruleForm.attendanceGroupName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "考勤类型", prop: "attendanceType" } },
                [
                  _c("div", { staticClass: "attendance-type-container" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "single-card",
                          _vm.ruleForm.attendanceType === "1" &&
                            "single-card-active",
                        ],
                        style: {
                          cursor: _vm.dataset.isDefault
                            ? "not-allowed"
                            : "pointer",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.attendanceTypeChange("1")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "main-title" }, [
                          _vm._v("固定制"),
                        ]),
                        _c("div", { staticClass: "second-title" }, [
                          _vm._v(
                            "\n              一个班次，固定班次时间。如09:00~18:00\n            "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ruleForm.attendanceType === "1",
                                expression: "ruleForm.attendanceType === '1'",
                              },
                            ],
                            staticClass: "active-tips",
                          },
                          [_c("i", { staticClass: "el-icon-check" })]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class: [
                          "single-card",
                          _vm.ruleForm.attendanceType === "2" &&
                            "single-card-active",
                        ],
                        style: {
                          cursor: _vm.dataset.isDefault
                            ? "not-allowed"
                            : "pointer",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.attendanceTypeChange("2")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "main-title" }, [
                          _vm._v("排班制"),
                        ]),
                        _c("div", { staticClass: "second-title" }, [
                          _vm._v("设置不同班次、周期循环"),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ruleForm.attendanceType === "2",
                                expression: "ruleForm.attendanceType === '2'",
                              },
                            ],
                            staticClass: "active-tips",
                          },
                          [_c("i", { staticClass: "el-icon-check" })]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "考勤人员", prop: "attendanceStaff" } },
                [
                  _c("el-select", {
                    staticClass: "attendanceStaff-selector",
                    staticStyle: { width: "100%", cursor: "pointer" },
                    attrs: {
                      disabled: _vm.dataset.isDefault,
                      multiple: "",
                      filterable: "",
                      remote: "",
                      "reserve-keyword": "",
                      placeholder: "请选择考勤人员",
                    },
                    on: {
                      "remove-tag": _vm.staffRemove,
                      focus: _vm.attendanceStaffFocus,
                    },
                    model: {
                      value: _vm.ruleForm.attendanceStaff,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "attendanceStaff", $$v)
                      },
                      expression: "ruleForm.attendanceStaff",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ruleForm.attendanceType === "1",
                  expression: "ruleForm.attendanceType === '1'",
                },
              ],
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "工作日设置", prop: "workday" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.ruleForm.workday,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "workday", $$v)
                        },
                        expression: "ruleForm.workday",
                      },
                    },
                    _vm._l(_vm.dayList, function (day) {
                      return _c(
                        "el-checkbox",
                        { key: day.value, attrs: { label: day.value } },
                        [_vm._v(_vm._s(day.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "考勤班次", prop: "attendance" } },
                [
                  _c("el-select", {
                    staticClass: "attendanceStaff-selector",
                    staticStyle: { width: "100%", cursor: "pointer" },
                    attrs: {
                      disabled: _vm.dataset.isDefault,
                      multiple: _vm.ruleForm.attendanceType === "2",
                      filterable: "",
                      remote: "",
                      "reserve-keyword": "",
                      placeholder: "请选择考勤班次",
                    },
                    on: {
                      "remove-tag": _vm.attendanceRemove,
                      focus: _vm.attendanceFocus,
                    },
                    model: {
                      value: _vm.ruleForm.attendance,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "attendance", $$v)
                      },
                      expression: "ruleForm.attendance",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ruleForm.attendanceType === "1",
                  expression: "ruleForm.attendanceType === '1'",
                },
              ],
              staticStyle: { "margin-bottom": "10px" },
            },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.showHolidaySelector,
                    callback: function ($$v) {
                      _vm.showHolidaySelector = $$v
                    },
                    expression: "showHolidaySelector",
                  },
                },
                [_vm._v("过滤节假日及添加补班日")]
              ),
            ],
            1
          ),
          _vm.showHolidaySelector && _vm.ruleForm.attendanceType === "1"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "节假日规则", prop: "holidayId" } },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.ruleForm.holidayId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "holidayId", $$v)
                            },
                            expression: "ruleForm.holidayId",
                          },
                        },
                        _vm._l(_vm.holidayList, function (item) {
                          return _c("el-option", {
                            key: item.ruleId,
                            attrs: { value: item.ruleId, label: item.ruleName },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer-container" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close", false)
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c("EmployeeSelection", {
        attrs: { dataset: _vm.employeeDialog },
        on: { close: _vm.employeeClose, submit: _vm.employeeSubmit },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.attendaceDialog.dialogVisible,
            "before-close": _vm.attendancedialogClose,
            "append-to-body": "",
            width: "828px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.attendaceDialog, "dialogVisible", $event)
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.attendaceDialog.dialogTitle))]),
          ]),
          _vm.attendaceDialog.dialogVisible
            ? _c("AttendanceSelectDialog", {
                attrs: {
                  attendanceSelectList: _vm.attendanceSelectList,
                  attendanceType: _vm.ruleForm.attendanceType,
                },
                on: {
                  close: _vm.attendancedialogClose,
                  submit: _vm.attendanceSubmit,
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }