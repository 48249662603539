var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container attendance-group-list" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.createGroup } },
              [_vm._v("添加考勤组 ")]
            ),
            _c(
              "el-button",
              {
                attrs: { plain: "", icon: "el-icon-refresh-right" },
                on: { click: _vm.freshHandler },
              },
              [_vm._v("刷新")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "header-filter-button",
            on: {
              click: function ($event) {
                _vm.dataset.filterLine.show = !_vm.dataset.filterLine.show
              },
            },
          },
          [
            _vm._v("\n      筛选\n      "),
            _vm.dataset.filterLine.show
              ? _c("i", { staticClass: "el-icon-arrow-down" })
              : _c("i", { staticClass: "el-icon-arrow-right" }),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
            scopedSlots: _vm._u([
              {
                key: "attendanceSlot",
                fn: function (row) {
                  return [
                    _c("el-tooltip", { attrs: { placement: "top" } }, [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        _vm._l(row.datas.shiftList, function (item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticStyle: {
                                display: "inline-block",
                                height: "18px",
                                "line-height": "18px",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.shiftDetailRender(item)) +
                                    "\n              "
                                ),
                              ]),
                              _c("br"),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("span", { staticClass: "attendance-span" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              row.datas.shiftList && row.datas.shiftList.length
                                ? row.datas.shiftList
                                    .map((item) => {
                                      return item.shiftName
                                    })
                                    .join("、")
                                : "-"
                            ) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                },
              },
              {
                key: "attendanceStaffSlot",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            row.datas.employeeList &&
                              row.datas.employeeList.length
                              ? row.datas.employeeList
                                  .map((item) => {
                                    return item.userName
                                  })
                                  .join("、")
                              : "-"
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "attendanceCountSlot",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(row.datas.employeeList.length) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            size: 600,
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            wrapperClosable: false,
            direction: "rtl",
            "before-close": _vm.dialogCloseHandler,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("AttendanceGroupDrawer", {
                attrs: { dataset: _vm.modifyDatas },
                on: { close: _vm.dialogCloseHandler },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }