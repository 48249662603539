<template>
  <div class="attendance-select-dialog">
    <div class="table-container scroll-view">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:workTimeSlot="row">
          <span v-if="row.datas.workTime">
            <span
              class="single-time-span"
              v-for="(item, index) in JSON.parse(row.datas.workTime)"
              :key="index"
            >
              <span>
                {{ item.startTime.time }}
                <span
                  class="next-day-span"
                  v-show="item.startTime.workDay === 'nextDay'"
                >
                  次日
                </span>
              </span>
              -
              <span>
                {{ item.endTime.time }}
                <span
                  class="next-day-span"
                  v-show="item.endTime.workDay === 'nextDay'"
                >
                  次日
                </span>
              </span>
            </span>
          </span>
          <span v-else> - </span>
        </template>
      </finalTable>
    </div>
    <div class="footer-container">
      <span class="footer-tips">
        移除考勤组关联班次，不影响排班表中已排的班次
      </span>
      <span>
        <el-button @click="$emit('close')">取消</el-button>
        <el-button type="primary" @click="submitHandler" v-preventReClick
          >确定</el-button
        >
      </span>
    </div>
  </div>
</template>

<script>
import { getShiftSettingDatas } from "@/api/business/shifts/shiftSetting.js";
import finalTable from "@/components/FinalTable";
export default {
  name: "attendanceSelectDialog",
  components: {
    finalTable,
  },
  props: {
    attendanceSelectList: {
      type: Array,
      default() {
        return [];
      },
    },
    attendanceType: {
      type: String,
      default() {
        return "1";
      },
    },
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
    attendanceSelectList: {
      handler(list) {
        this.selectionList = list;
      },
      immediate: true,
      deep: true,
    },
    attendanceType: {
      handler(value) {
        this.dataset.selection.singleSelect = value === "1";
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getTableDatas();
  },
  data() {
    return {
      loadingFlag: false,
      selectionList: [],
      dataset: {
        // 是否需要分页
        paginationConfig: {
          need: false,
        },
        // 是否需要查询行
        filterLine: {
          show: false,
        },
        // 行勾选
        selection: {
          need: true,
          singleSelect: true,
        },
        header: [
          {
            prop: "shiftName",
            label: "班次名称",
            width: "200",
          },
          {
            prop: "workTime",
            label: "班次时段",
            width: "",
          },
          {
            prop: "shiftDuration",
            label: "班次时长",
            width: "150",
          },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {},
        // 表格内容配置
        detailConfig: {
          workTime: {
            type: "slot",
            slotName: "workTimeSlot",
          },
        },
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  methods: {
    setCheckedItems(row) {
      this.$refs.finalTableRef.toggleRowSelection(row);
    },
    tableEventHandler(datas) {
      if (datas.type === "updateSelectionList") {
        this.selectionList = datas.list;
      }
    },
    getTableDatas() {
      this.loadingFlag = true;
      getShiftSettingDatas()
        .then((res) => {
          this.dataset.tableData = res;
        })
        .finally(() => {
          this.loadingFlag = false;
          const selectedList = this.selectionList.map((item) => item.id);
          this.dataset.tableData.forEach((item) => {
            if (selectedList.includes(item.id)) {
              this.setCheckedItems(item);
            }
          });
        });
    },
    submitHandler() {
      this.$emit("submit", this.selectionList);
    },
  },
};
</script>

<style lang="less" scoped>
.attendance-select-dialog {
  .next-day-span {
    color: #1a4cec;
    font-size: 12px;
  }
  .footer-container {
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-tips {
      font-size: 14px;
      font-weight: 400;
      color: #2f3941;
    }
  }
  .single-time-span {
    margin-right: 12px;
  }
}
</style>