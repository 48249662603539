var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "employee-selection-componnet" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.dialogVisible,
            "before-close": _vm.dialogCloseHandler,
            width: "1000px",
            "append-to-body": "",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "dialogVisible", $event)
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.dataset.mainTitle))]),
            _c(
              "span",
              {
                staticClass: "second-title",
                staticStyle: { "font-size": "12px", color: "#666" },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.dataset.secondTitle) + "\n      "
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "employee-main-container" }, [
            _c("div", { staticClass: "choose-part" }, [
              _c("div", [_vm._v("选择：")]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeLoading,
                      expression: "treeLoading",
                    },
                  ],
                  staticClass: "detail-container",
                },
                [
                  _c("el-tree", {
                    ref: "organizationTree",
                    attrs: {
                      data: _vm.organizationDatas,
                      "node-key": "id",
                      "auto-expand-parent": true,
                      "highlight-current": true,
                      "show-checkbox": "",
                      "default-expand-all": "",
                    },
                    on: { "check-change": _vm.handleCheckChange },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "selected-part" }, [
              _c("div", [_vm._v("已选：")]),
              _c(
                "div",
                { staticClass: "detail-container" },
                _vm._l(this.selectList, function (user, index) {
                  return _c("div", { key: index, staticClass: "single-user" }, [
                    _c("span", { staticClass: "left-part" }, [
                      _c("span", { staticClass: "icon-span" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              user.userName.slice(user.userName.length - 1)
                            ) +
                            "\n              "
                        ),
                      ]),
                      _c("span", [
                        _c("div", { staticClass: "name-span" }, [
                          _vm._v(_vm._s(user.userName)),
                        ]),
                        _c("div", { staticClass: "dept-span" }, [
                          _vm._v(_vm._s(user.deptName)),
                        ]),
                      ]),
                    ]),
                    _c("span", { staticClass: "right-part" }, [
                      _c("i", {
                        staticClass: "el-icon-close",
                        on: {
                          click: function ($event) {
                            return _vm.deleteSingleUser(user)
                          },
                        },
                      }),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "employee-footer-container" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close", false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.checkLoading,
                      expression: "checkLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.submitHandler },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }