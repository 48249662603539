<template>
  <div class="attendance-group-dialog">
    <el-form
      :model="ruleForm"
      :rules="rules"
      label-position="top"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <!-- 考勤组名称 -->
      <el-row>
        <el-form-item label="考勤名称" prop="attendanceGroupName">
          <el-input
            :disabled="dataset.isDefault"
            v-model="ruleForm.attendanceGroupName"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-row>
      <!-- 考勤类型 -->
      <el-row>
        <el-form-item label="考勤类型" prop="attendanceType">
          <div class="attendance-type-container">
            <div
              :class="[
                'single-card',
                ruleForm.attendanceType === '1' && 'single-card-active',
              ]"
              :style="{
                cursor: dataset.isDefault ? 'not-allowed' : 'pointer',
              }"
              @click="attendanceTypeChange('1')"
            >
              <div class="main-title">固定制</div>
              <div class="second-title">
                一个班次，固定班次时间。如09:00~18:00
              </div>
              <div class="active-tips" v-show="ruleForm.attendanceType === '1'">
                <i class="el-icon-check"></i>
              </div>
            </div>
            <div
              :class="[
                'single-card',
                ruleForm.attendanceType === '2' && 'single-card-active',
              ]"
              :style="{
                cursor: dataset.isDefault ? 'not-allowed' : 'pointer',
              }"
              @click="attendanceTypeChange('2')"
            >
              <div class="main-title">排班制</div>
              <div class="second-title">设置不同班次、周期循环</div>
              <div class="active-tips" v-show="ruleForm.attendanceType === '2'">
                <i class="el-icon-check"></i>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-row>
      <!-- 考勤人员 -->
      <el-row>
        <el-form-item label="考勤人员" prop="attendanceStaff">
          <el-select
            :disabled="dataset.isDefault"
            v-model="ruleForm.attendanceStaff"
            multiple
            filterable
            remote
            reserve-keyword
            @remove-tag="staffRemove"
            placeholder="请选择考勤人员"
            @focus="attendanceStaffFocus"
            style="width: 100%; cursor: pointer"
            class="attendanceStaff-selector"
          >
            <!-- <el-option
              v-for="item in []"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option> -->
          </el-select>
        </el-form-item>
      </el-row>
      <!-- 工作日设置 -->
      <el-row v-show="ruleForm.attendanceType === '1'">
        <el-form-item label="工作日设置" prop="workday">
          <el-checkbox-group v-model="ruleForm.workday">
            <el-checkbox
              v-for="day in dayList"
              :label="day.value"
              :key="day.value"
              >{{ day.label }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-row>
      <!-- 考勤班次 -->
      <el-row>
        <el-form-item label="考勤班次" prop="attendance">
          <el-select
            :disabled="dataset.isDefault"
            v-model="ruleForm.attendance"
            :multiple="ruleForm.attendanceType === '2'"
            filterable
            remote
            reserve-keyword
            placeholder="请选择考勤班次"
            @remove-tag="attendanceRemove"
            @focus="attendanceFocus"
            style="width: 100%; cursor: pointer"
            class="attendanceStaff-selector"
          >
          </el-select>
        </el-form-item>
      </el-row>
      <!-- 过滤节假日及添加补班日 -->
      <el-row
        v-show="ruleForm.attendanceType === '1'"
        style="margin-bottom: 10px"
      >
        <el-checkbox v-model="showHolidaySelector"
          >过滤节假日及添加补班日</el-checkbox
        >
      </el-row>
      <!-- 节假日规则 -->
      <el-row v-if="showHolidaySelector && ruleForm.attendanceType === '1'">
        <el-form-item label="节假日规则" prop="holidayId">
          <el-select
            clearable
            v-model="ruleForm.holidayId"
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="item in holidayList"
              :value="item.ruleId"
              :key="item.ruleId"
              :label="item.ruleName"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
    </el-form>
    <div class="footer-container">
      <el-button @click="$emit('close', false)">取消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
    </div>
    <EmployeeSelection
      :dataset="employeeDialog"
      @close="employeeClose"
      @submit="employeeSubmit"
    />
    <el-dialog
      :visible.sync="attendaceDialog.dialogVisible"
      :before-close="attendancedialogClose"
      append-to-body
      width="828px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <span>{{ attendaceDialog.dialogTitle }}</span>
      </template>
      <AttendanceSelectDialog
        v-if="attendaceDialog.dialogVisible"
        :attendanceSelectList="attendanceSelectList"
        :attendanceType="ruleForm.attendanceType"
        @close="attendancedialogClose"
        @submit="attendanceSubmit"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getHolidayList } from "@/api/business/holiday/holiday.js";
import {
  createAttendanceGroup,
  updateAttendanceGroup,
} from "@/api/business/shifts/attendanceGroup.js";
import { deepClone } from "@/utils/utils.js";
import EmployeeSelection from "@/components/EmployeeSelection";
import AttendanceSelectDialog from "./attendanceSelectDialog.vue";
export default {
  name: "attendanceGroupDialog",
  components: {
    EmployeeSelection,
    AttendanceSelectDialog,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(value) {
        if (value.id) {
          this.displayDatas(value);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    const validatorStaff = (rule, value, callback) => {
      if (this.ruleForm.attendanceStaff.length === 0) {
        callback(new Error("请选择考勤人员"));
      }
      callback();
    };
    const validatorWorkday = (rule, value, callback) => {
      if (this.ruleForm.attendanceType === "1" && !value.length) {
        callback(new Error("请选择工作日"));
      }
      callback();
    };
    return {
      attendanceSelectList: [],
      attendanceListResource: [],
      userListResource: [],
      holidayList: [],
      employeeDialog: {
        dialogVisible: false,
        mainTitle: "添加人员",
        secondTitle: "（根据员工主部门进行展示）",
        selectList: [],
        attendanceGroupId: null,
      },
      attendaceDialog: {
        dialogVisible: false,
        dialogTitle: "选择班次",
      },
      companyList: [],
      projectList: [],
      showHolidaySelector: false,
      dayList: [
        {
          label: "周一",
          value: 1,
        },
        {
          label: "周二",
          value: 2,
        },
        {
          label: "周三",
          value: 3,
        },
        {
          label: "周四",
          value: 4,
        },
        {
          label: "周五",
          value: 5,
        },
        {
          label: "周六",
          value: 6,
        },
        {
          label: "周日",
          value: 7,
        },
      ],
      ruleForm: {
        attendanceGroupName: "",
        attendanceType: "1", //考勤类型 "1":固定制 "2":排班制   字符串类型
        attendanceStaff: [],
        workday: [],
        holidayId: [],
        attendance: [],
      },
      rules: {
        attendanceGroupName: [
          { required: true, message: "请填写考勤组名称", trigger: "blur" },
        ],
        attendanceType: [
          { required: true, message: "请选择考勤类型", trigger: "blur" },
        ],
        attendanceStaff: [
          { required: true, validator: validatorStaff, trigger: "change" },
        ],
        workday: [
          { required: true, validator: validatorWorkday, trigger: "blur" },
        ],
        attendance: [
          {
            required: true,
            message: "请选择考勤班次",
            trigger: ["blur", "change"],
          },
        ],
        holidayId: [
          {
            required: true,
            message: "请选择节假日规则",
            trigger: ["blur", "change"],
          },
        ],
        companyName: [
          { required: true, message: "请选择公司名称", trigger: "blur" },
        ],
        projectName: [
          { required: true, message: "请选择项目名称", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getHolidayDatas();
  },
  methods: {
    staffRemove(value) {
      this.userListResource = this.userListResource.filter(
        (item) => item.userName !== value
      );
    },
    attendanceRemove(value) {
      this.attendanceListResource = this.attendanceListResource.filter(
        (item) => item.shiftName !== value
      );
    },
    displayDatas(datas) {
      this.ruleForm.attendanceGroupName = datas.attendanceGroupName;
      this.ruleForm.attendanceType = datas.attendanceType || "1";
      // this.userListResource
      this.userListResource = datas.employeeList;
      this.ruleForm.attendanceStaff = datas.employeeList.map((item) => {
        return item.userName;
      });
      this.attendanceListResource = datas.shiftList;
      const tempShiftList = datas.shiftList.map((item) => {
        return item.shiftName;
      });
      this.ruleForm.attendance =
        datas.attendanceType === "1" ? tempShiftList.join(",") : tempShiftList;
      this.ruleForm.workday = datas.workday
        ? datas.workday.split(",").map((item) => Number(item))
        : [];
      this.ruleForm.holidayId = datas.holidayId;
      this.showHolidaySelector = datas.holidayId ? true : false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler();
        } else {
          return false;
        }
      });
    },
    submitHandler() {
      const params = this.buildParams();
      console.log("提交参数：", params);
      if (this.dataset.id) {
        params.id = this.dataset.id;
        // 如果有人员删减
        if (this.checkEmployeeReduce(params)) {
          this.$confirm(
            "移除当前考勤组的员工，员工将自动进入【默认考勤组】，系统将删除该员工当前时间之后的班次，并继承【默认考勤组】班次，是否继续执行该操作？",
            this.$t("commons.warning"),
            {
              confirmButtonText: this.$t("commons.confirm"),
              cancelButtonText: this.$t("commons.cancel"),
              type: "warning",
            }
          )
            .then(() => {
              this.updateHandler(params);
            })
            .catch((error) => {
              console.log(`未删除，原因 => ${error}`);
            });
        } else {
          this.updateHandler(params);
        }
      } else {
        createAttendanceGroup(params).then((res) => {
          if (res === 1) {
            this.$message.success("创建考勤组成功！");
            this.$emit("close", true);
          }
        });
      }
    },
    checkEmployeeReduce(params) {
      const resourceList = this.dataset.employeeList;
      const currentList = params.attendanceStaff;
      for (let i = 0; i < resourceList.length; i++) {
        if (!currentList.includes(resourceList[i].empId)) {
          return true;
        }
      }
      return false;
    },
    updateHandler(params) {
      updateAttendanceGroup(params).then((res) => {
        if (res === 1) {
          this.$message.success("修改考勤组成功！");
          this.$emit("close", true);
        }
      });
    },
    buildParams() {
      let tempParams = deepClone(this.ruleForm);
      tempParams.attendance = this.attendanceListResource
        .map((item) => {
          return item.id;
        })
        .join(",");
      tempParams.attendanceStaff = this.userListResource
        .map((item) => item.empId)
        .join(",");
      tempParams.holidayId = this.showHolidaySelector
        ? tempParams.holidayId
        : null;
      tempParams.workday = tempParams.workday.join(",");
      return tempParams;
    },
    employeeClose() {
      this.employeeDialog.dialogVisible = false;
    },
    employeeSubmit(userList) {
      this.userListResource = userList;
      this.ruleForm.attendanceStaff = userList.map((item) => item.userName);
      this.employeeDialog.dialogVisible = false;
    },
    attendanceStaffFocus() {
      this.employeeDialog.selectList = this.userListResource;
      this.employeeDialog.attendanceGroupId = this.dataset.id;
      this.employeeDialog.dialogVisible = true;
    },
    attendanceFocus() {
      this.attendanceSelectList = this.attendanceListResource;
      this.attendaceDialog.dialogVisible = true;
    },
    attendancedialogClose() {
      this.attendaceDialog.dialogVisible = false;
    },
    attendanceSubmit(attendanceList) {
      this.attendanceListResource = attendanceList;
      this.ruleForm.attendance = attendanceList.map((item) => {
        return item.shiftName;
      });
      this.attendaceDialog.dialogVisible = false;
    },
    getHolidayDatas() {
      getHolidayList().then((res) => {
        this.holidayList = res.rows.map((item) => {
          return {
            ruleId: item.ruleId,
            ruleName: item.ruleName,
          };
        });
      });
    },
    attendanceTypeChange(type) {
      if (this.dataset.isDefault) return;
      this.ruleForm.attendance = type === "1" ? [] : "";
      this.attendanceListResource = [];
      this.$nextTick(() => {
        this.ruleForm.attendanceType = type;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.attendance-group-dialog {
  padding: 20px;
  height: calc(100vh - 107px);
  overflow: auto;
  .footer-container {
    text-align: right;
    position: absolute;
    right: 20px;
    bottom: 10px;
  }
  .attendance-type-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .single-card {
      width: 255px;
      height: 70px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      padding: 0 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      cursor: pointer;
      position: relative;
      & > div {
        line-height: 20px;
      }
      .main-title {
        font-size: 14px;
        font-weight: bold;
        color: #303133;
      }
      .second-title {
        font-size: 12px;
        font-weight: 400;
        color: #97a8be;
      }
      .active-tips {
        height: 24px;
        width: 24px;
        background: #1a4cec;
        position: absolute;
        right: 0;
        bottom: 0;
        clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
        .el-icon-check {
          position: absolute;
          bottom: 1px;
          right: 1px;
          font-size: 12px;
          color: #ffffff;
        }
      }
    }
    .single-card-active {
      border: 1px solid #1a4cec;
    }
  }
  ::v-deep .attendanceStaff-selector .el-select__input {
    cursor: pointer !important;
  }
}
</style>