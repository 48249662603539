<template>
  <div class="app-container attendance-group-list">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="createGroup">添加考勤组 </el-button>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:attendanceSlot="row">
          <el-tooltip placement="top">
            <div slot="content">
              <!-- 多行信息<br />第二行信息 -->
              <span
                v-for="(item, index) in row.datas.shiftList"
                :key="index"
                style="display: inline-block; height: 18px; line-height: 18px"
              >
                <span>
                  {{ shiftDetailRender(item) }}
                </span>
                <br />
              </span>
              <!-- {{ attendanceListRender(row.datas.shiftList) }} -->
            </div>
            <span class="attendance-span">
              {{
                row.datas.shiftList && row.datas.shiftList.length
                  ? row.datas.shiftList
                      .map((item) => {
                        return item.shiftName;
                      })
                      .join("、")
                  : "-"
              }}
            </span>
          </el-tooltip>
        </template>
        <template v-slot:attendanceStaffSlot="row">
          <span>
            {{
              row.datas.employeeList && row.datas.employeeList.length
                ? row.datas.employeeList
                    .map((item) => {
                      return item.userName;
                    })
                    .join("、")
                : "-"
            }}
          </span>
        </template>
        <template v-slot:attendanceCountSlot="row">
          <span>
            {{ row.datas.employeeList.length }}
          </span>
        </template>
      </finalTable>
    </div>
    <!-- 新增&编辑弹框 -->
    <el-drawer
      :size="600"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :wrapperClosable="false"
      direction="rtl"
      :before-close="dialogCloseHandler"
    >
      <AttendanceGroupDrawer
        v-if="dialogVisible"
        @close="dialogCloseHandler"
        :dataset="modifyDatas"
      />
    </el-drawer>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import AttendanceGroupDrawer from "./attendanceGroupDrawer.vue";
import {
  getAttendanceGroupList,
  deleteAttendanceGroup,
  getAttendanceGroupPage,
} from "@/api/business/shifts/attendanceGroup.js";
export default {
  name: "attendanceGroupList",
  components: {
    finalTable,
    AttendanceGroupDrawer,
  },
  data() {
    return {
      modifyDatas: {},
      dialogVisible: false,
      dialogTitle: "新增考勤组",
      loadingFlag: false,
      requestParams: {
        current: 1,
        rowCount: 10,
      },
      dataset: {
        // 是否需要分页
        paginationConfig: {
          need: true,
        },
        // 是否需要查询行
        filterLine: {
          show: true,
        },
        // 行勾选
        selection: {
          need: false,
        },
        header: [
          {
            prop: "attendanceGroupName",
            label: "考勤组名称",
            width: "180",
          },
          {
            prop: "attendanceType",
            label: "考勤类型",
            width: "180",
          },
          {
            prop: "attendance",
            label: "适用班次",
            hideToolTip: true,
            width: "400",
          },
          {
            prop: "attendanceStaff",
            label: "考勤人员",
            width: "",
          },
          {
            prop: "attendanceCount",
            label: "考勤人数",
            width: "100",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "120",
          },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          attendanceGroupName: {
            type: "input",
            label: "考勤组名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入考勤组名称",
            prefixIcon: "el-icon-search",
          },
          attendanceType: {
            type: "selector",
            label: "考勤类型",
            value: "",
            placeholder: "请选择考勤类型",
            actionType: "goSearch",
            optionList: [
              {
                label: "固定制",
                value: "1",
              },
              {
                label: "排班制",
                value: "2",
              },
            ],
          },
        },
        // 表格内容配置
        detailConfig: {
          attendance: {
            type: "slot",
            slotName: "attendanceSlot",
          },
          attendanceStaff: {
            type: "slot",
            slotName: "attendanceStaffSlot",
          },
          attendanceCount: {
            type: "slot",
            slotName: "attendanceCountSlot",
          },
          attendanceType: {
            type: "enumerationColumn",
            emuList: {
              1: "固定制",
              2: "排班制",
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "modify",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
                hideProp: "isDefault",
                hideValue: true,
              },
            ],
          },
        },
        advanceFilterConfig: {
          deviceName: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          productKey: {
            inline: true,
            value: "",
          },
          nodeType: {
            inline: true,
            value: "",
          },
          deviceStatus: {
            inline: true,
            value: "",
          },
          enableStatus: {
            inline: true,
            value: "",
          },
          creationDate: {
            type: "dateRange",
            label: "注册时间",
            value: [],
          },
          activationTime: {
            type: "dateRange",
            label: "激活时间",
            value: [],
          },
          lastOnlineTime: {
            type: "dateRange",
            label: "最后上线时间",
            value: [],
          },
          tagList: {
            type: "tagList",
            label: "标签",
            tagType: "device",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.getTableList();
  },
  methods: {
    shiftDetailRender(item) {
      let result = `${item.shiftName}: `;
      const workTimeList = JSON.parse(item.workTime);
      let workTimeTemp = [];
      workTimeList.forEach((item) => {
        workTimeTemp.push(
          `${item.startTime.time}${
            item.startTime.workDay === "nextDay" ? "次日" : ""
          }-${item.endTime.time}${
            item.endTime.workDay === "nextDay" ? "次日" : ""
          }`
        );
      });
      result += workTimeTemp.join("、");
      return result;
    },
    dialogCloseHandler(freshFlag) {
      this.dialogVisible = false;
      if (freshFlag === true) this.getTableList();
    },
    getTableList() {
      for (let key in this.requestParams) {
        if (!this.requestParams[key]) {
          delete this.requestParams[key];
        }
      }
      this.loadingFlag = true;
      getAttendanceGroupPage(this.requestParams)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    createGroup() {
      this.dialogTitle = "新增考勤组";
      this.modifyDatas = {};
      this.dialogVisible = true;
    },
    freshHandler() {
      this.getTableList();
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "modify":
            this.modifyHandler(datas.row);
            break;
          case "delete":
            this.deleteHandler(datas.row);
            break;
        }
      }
    },
    modifyHandler(datas) {
      this.dialogTitle = "修改考勤组";
      this.modifyDatas = datas;
      this.dialogVisible = true;
    },
    deleteHandler({ attendanceGroupName, id }) {
      this.$confirm(
        `删除后，小组内所有成员将自动加入【默认考勤组】，系统将删除小组成员当前时间之后的班次，并继承【默认考勤组】班次，确认删除【${attendanceGroupName}】考勤组吗？`,
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteAttendanceGroup({
            id,
          }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.getTableList();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.attendance-group-list {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
  .attendance-span {
    width: 380px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ::v-deep .el-drawer__header > span {
    font-size: 18px;
    font-weight: bold;
    color: #2f3941;
  }
}
</style>