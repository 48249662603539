var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "attendance-select-dialog" }, [
    _c(
      "div",
      { staticClass: "table-container scroll-view" },
      [
        _c("finalTable", {
          ref: "finalTableRef",
          attrs: { datas: _vm.dataset },
          on: { tableEventHandler: _vm.tableEventHandler },
          scopedSlots: _vm._u([
            {
              key: "workTimeSlot",
              fn: function (row) {
                return [
                  row.datas.workTime
                    ? _c(
                        "span",
                        _vm._l(
                          JSON.parse(row.datas.workTime),
                          function (item, index) {
                            return _c(
                              "span",
                              { key: index, staticClass: "single-time-span" },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.startTime.time) +
                                      "\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            item.startTime.workDay ===
                                            "nextDay",
                                          expression:
                                            "item.startTime.workDay === 'nextDay'",
                                        },
                                      ],
                                      staticClass: "next-day-span",
                                    },
                                    [
                                      _vm._v(
                                        "\n                次日\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v("\n            -\n            "),
                                _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.endTime.time) +
                                      "\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            item.endTime.workDay === "nextDay",
                                          expression:
                                            "item.endTime.workDay === 'nextDay'",
                                        },
                                      ],
                                      staticClass: "next-day-span",
                                    },
                                    [
                                      _vm._v(
                                        "\n                次日\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _c("span", [_vm._v(" - ")]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c("div", { staticClass: "footer-container" }, [
      _c("span", { staticClass: "footer-tips" }, [
        _vm._v("\n      移除考勤组关联班次，不影响排班表中已排的班次\n    "),
      ]),
      _c(
        "span",
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.submitHandler },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }